// components/SubscriptionPage.module.scss
.subscriptionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 50px;
}

.subscriptionPaper {
  padding: 20px;
  border-radius: 20px;
}
