.gradientText {
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(70deg, #4876ee 0%, #00d3ab 50%);
  background: -webkit-linear-gradient(70deg, #4876ee 0%, #00d3ab 50%);
  background: linear-gradient(70deg, #4876ee 0%, #00d3ab 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4876ee",endColorstr="#00d3ab",GradientType=1);
  color: transparent;
  background-clip: text;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 80%;
  margin: 100px auto 50px auto;

  // on phone full width;
  @media (max-width: 768px) {
    max-width: 100%;
    margin: 100px auto 0px auto;
  }
}

.firstSection {
  padding: 0 48px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: left;
  gap: 20px;
  margin: 80px auto;
  max-width: 1200px;
  img {
    max-width: 70%;
  }
  button {
    margin: 0 auto;
    height: 50px !important;
    width: 200px !important;
    font-size: 18px;
    font-weight: bold;
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 40px auto;
    text-align: center;
    img {
      max-width: 100%;
      margin: 40px auto 0 auto;
      transform: translate(-2px, 0px);
    }
  }
  .firstSectionContent {
    color: white;
    margin: 20px 0;
    gap: 20px;
    display: flex;
    flex-direction: column;
    transform: translate(50px, 0px) rotate3d(1, 1, 0, 15deg) scale(1.2);
    max-width: 100%;
    @media (max-width: 768px) {
      transform: /* rotate3d(1, 1, 0, 15deg) */ scale(1.1);
    }
  }
}

.secondSection {
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  width: 100%;
  max-width: 1050px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .secondSectionContent {
    margin: 0 auto;
  }
  button {
    color: white;
    height: 50px !important;
    width: 200px !important;
    font-size: 24px;
    font-weight: bold;
    border-radius: 20px;
    background-color: black;
    margin-top: 40px !important;
  }

  img {
    margin-top: 20px;
    max-width: 100%;
    max-height: 30vh;
  }
  @media (max-width: 768px) {
    border-radius: 0 !important;
    width: 100%;
    margin-bottom: 0px;
    flex-direction: column;
    justify-content: center;
    img {
      max-height: unset;
    }
  }
}

.footer {
  text-align: center;
  border-radius: 70px;
  background: #f4f6fb;
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
  max-width: 1050px;
  @media (max-width: 768px) {
    border-radius: 0;
    max-width: unset;
  }
}
