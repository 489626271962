.quinipoloSuccessContainer {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 132px);
  margin-top: 80px;
  justify-content: space-evenly;
  padding: 0 14px;
  width: 100%;
}

.correctionSuccessContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 132px);
  margin-top: 80px;
  justify-content: space-evenly;
  padding: 0 14px;
  width: 100%;
}

.qrCode {
  margin: 32px;
  height: 232px;
}

.returnButton {
  color: rgb(60, 60, 60);
}

.correct15 {
  // Styles to show letters in gold
  color: #7cb305;
  font-weight: bold;
}

.reminder,
.pointsCell {
  font-weight: bold;
}

.copyCorrection {
  font-size: 14px;
  margin: 8px 0;
}
