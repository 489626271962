.form {
  text-align: left;
}

.submitButton {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.datePickerContainer {
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.datePicker,
.timePicker {
  margin: 0 5px;
  flex: 1;
  height: 30px;
  border: 1px solid #ccc;

  padding: 0 10px;
  font-size: 16px;
  outline: none;
}

.dateTimeDisclaimer {
  font-weight: bold;
  margin: 0 5px;
  margin-bottom: 16px;
  color: white;
}

// for mobile devices under   481px
@media all and (max-width: 481px) {
  .datePickerPopup {
    position: relative;
    left: 0 !important;
  }
}
