.signUpWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 72px);
  width: 100%;
  margin: auto;
  margin-top: 72px;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: 300px;
  height: 100%;
}
