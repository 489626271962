.leagueDashboardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  margin-top: 64px;
  max-height: calc(100vh - 64px);
}

.leagueTitle {
  text-align: left;
}

.actionsTitle {
  text-align: left;
  margin-top: 30px;
}

.disclaimer {
  text-align: left;
  margin: 4px 0px 8px 0;
  font-size: 12px;
  color: #333;
}
