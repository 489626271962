.loadingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loadingMessage {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 24px;
  color: white;
}
