.dashboardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 24px;
  max-height: calc(100vh - 64px);
  width: 100%;
}

.leaguesTitle {
  text-align: left;
  margin-top: 30px;
}

.noActionsMessage {
  font-size: 14px;
  color: #6e6e6e;
  margin: 10px;
}
